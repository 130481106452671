import "./App.css";
import Header from "../src/components/Header.jsx";
import Home from "../src/components/Home.jsx";
import Product from "../src/components/productSlider.jsx";
import Services from "../src/components/Services.jsx";
import Testimonial from "../src/components/Testimonials.jsx";
import Footer from "../src/components/Footer.jsx";
import HomeBanner from "../src/components/HomeBanner.jsx";
// import ContactForm from "../src/components/ContactForm.jsx";
import "../src/css/responsive.css";

import "../src/css/style.css";
import Usps from "./components/Usps.jsx";
import Application from "./components/Application.jsx";
import ProductMobile from "./components/ProductMobile.jsx";
import ApplicationMobile from "./components/ApplicationMobile.jsx";
import ServicesMobile from "./components/ServicesMobile.jsx";

function App() {
  return (
    <>
      <Header />
      <HomeBanner />
      <Home />
      <Product />
      <ProductMobile />
      <Usps />
      <Application />
      <ApplicationMobile />
      <Services />
      <ServicesMobile/>
      <Testimonial />
      <Footer />
    </>
  );
}

export default App;

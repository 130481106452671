import React, { useState, useEffect } from "react";
import "../css/homebanner.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import productImg1 from "../image/home/product-bg.jpg";
import ProductBrochureForm from "./ProductBrochureForm";
import ProductEnqueryForm from "./ProductEnqueryForm";

const ProductMobile = () => {
  const [homeProducts, setHomeProducts] = useState([]);
  const [activeAccordionIndex, setActiveAccordionIndex] = useState(0);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenTwo, setIsOpenTwo] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const now = new Date();
        const seconds = now.getSeconds().toString().padStart(2, "0");
        const response = await fetch(
          `https://pgsexim.com/backend/wp-json/custom/v1/home-product-metadata?${seconds}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        setTimeout(() => {
          setHomeProducts(jsonData);
        }, 2000);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const openModal = () => {
    setIsOpen(true);
  };

  const openModal1 = () => {
    setIsOpenTwo(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const closeModal1 = () => {
    setIsOpenTwo(false);
  };

  const toggleAccordion = (index) => {
    setActiveAccordionIndex(index === activeAccordionIndex ? null : index);
  };

  return (
    <>
      <section
        className="commonSec productSec productMobi "
        id="sec2"
        style={{ backgroundImage: `url(${productImg1})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="text-center">
                <span className="subTxt">
                  {homeProducts.product_small_title}
                </span>
              </h3>
              <h2 className="secH text-center">
                {homeProducts.product_heading}
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="accordion productAccordion">
                {homeProducts.length === 0 ? (
                  <h2 className="secH text-center">Loading...</h2>
                ) : (
                  homeProducts.all_product_details.map((product, index) => (
                    <AccordionItem
                      key={index}
                      product={product}
                      isOpen={index === activeAccordionIndex}
                      toggleAccordion={() => toggleAccordion(index)}
                      openModal={openModal}
                      openModal1={openModal1}
                      closeModal={closeModal}
                      closeModal1={closeModal1}
                      modalIsOpen={modalIsOpen}
                      modalIsOpenTwo={modalIsOpenTwo}
                    />
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const AccordionItem = ({
  product,
  isOpen,
  toggleAccordion,
  openModal,
  openModal1,
  closeModal,
  closeModal1,
  modalIsOpen,
  modalIsOpenTwo,
}) => {
  return (
    <div className={`accordion-item ${isOpen ? "active" : ""}`}>
      <button
        className={`accordion-button ${isOpen ? "active" : ""}`}
        onClick={toggleAccordion}
      >
        {product.tab_product_heading}
      </button>
      {isOpen && (
        <div className="accordion-content">
          <div className="productItem" id="ptab-1">
            <div className="smithTxt">
              <h3>Smith Motors</h3>
            </div>
            <div className="productSliderImg">
              <img src={product.product_image} alt="" />
            </div>
            <div className="productContent">
              <h4>Platform Trolley</h4>
              <div className="priceWrap">
                <span>{product.product_price}</span>
              </div>
              <div className="productDesc">
                <h5>Specifications:</h5>
                <ul>
                  {product.add_specification.map((specification, index) => (
                    <li key={index}>
                      <p>
                        <span>{specification.specification_label}</span>:{" "}
                        {specification.specification_value}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="productBtn">
                <a href="#" className="btnH" onClick={openModal}>
                  Download Brochure
                </a>
                <a href="#" className="btnH" onClick={openModal1}>
                  Enquiry Now
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
      {modalIsOpen && (
        <div className="form-popup-bg" id="pop1">
          <div className="form-container_popup">
            <button
              onClick={closeModal}
              className="relative right-0 float-right text-red-600 font-bolder text-2xl"
            >
              X
            </button>
            <h2>Download Brochure</h2>
            <ProductBrochureForm />
          </div>
        </div>
      )}
      {modalIsOpenTwo && (
        <div className="form-popup-bg" id="pop2">
          <div className="form-container_popup">
            <button
              onClick={closeModal1}
              className="relative right-0 float-right text-red-600 font-bolder text-2xl"
            >
              X
            </button>
            <h2>Quick Connect</h2>
            <ProductEnqueryForm />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductMobile;

import React, { useState, useEffect } from "react";
import "../css/style.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { RiLinkedinFill } from "react-icons/ri";
import ContactForm from "./ContactForm.jsx";

const Footer = () => {
  const [homeFooter, sethomeFooter] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
        try {
            const now = new Date();
            const seconds = now.getSeconds().toString().padStart(2, '0');
            const response = await fetch(`https://pgsexim.com/backend/wp-json/custom/v1/homepage-footer-metadata?${seconds}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setTimeout(() => {
              sethomeFooter(jsonData);
            }, 2000);
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    fetchData();
}, []); 

  return (
    <>
      <footer className="footerSec commonSec" id="sec5">
        <div className="bannerBottom">
          <svg
            className="waves"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
            shape-rendering="auto"
          >
            <defs>
              <path
                id="gentle-wave"
                d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
              ></path>
            </defs>
            <g className="parallax">
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="0"
                fill="rgba(2,36,97,0.7"
              ></use>
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="3"
                fill="rgba(2,36,97,0.5)"
              ></use>
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="5"
                fill="rgba(2,36,97,0.3)"
              ></use>
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="#022461"></use>
            </g>
          </svg>
        </div>
        <div className="footerForm">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 footerWidgetMain">
                <div className="footerWidget footerWidget1">
                  <h4>Address:</h4>
                  <p>
                  {homeFooter.address}
                  </p>
                </div>
                <div className="footerWidget footerWidget2">
                  <h4>Phone No:</h4>
                  <a href="#">{homeFooter.number}</a>
                </div>
                <div className="footerWidget footerWidget3">
                  <h4>Mail:</h4>
                  <a href="#">{homeFooter.mail}</a>
                </div>
                <div className="footerWidget footerWidget4">
                  <h4>Follow Us</h4>
                  <div className="social">

                  {homeFooter.banner_facebook_link && (
                  <a href={homeFooter.banner_facebook_link}><FaFacebookF /></a>
                  )}
                        {homeFooter.banner_instagram_link && (
                    <a href={homeFooter.banner_instagram_link}><FaInstagram /></a>
                  )}
                     {homeFooter.banner_linkedin_link && (
                    <a href={homeFooter.banner_linkedin_link}><RiLinkedinFill /></a>
                  )}

                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="footerInner commonSec" id="sec5">
                  <div className="headingWrap">
                    <h2 className="secH">Get In Touch</h2>
                    <p>
                     {homeFooter.address}
                    </p>
                  </div>
                  <ContactForm />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyrightWrap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="copyrightTxt">
                  <div className="leftTxt">
                    <p>
                     {homeFooter.copy_write}{" "}
                      <a href="#">PGS Exim</a>
                    </p>
                  </div>
                  <div className="rightTxt">
                    <p>
                      Design & Developed by <a href="https://promotedge.net/">Promotedge</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

import React, { useState, useEffect } from "react";
import "../css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";

const Home = () => {
  const [homeAbout, sethomeAbout] = useState([]);
  const [aboutPgs, setaboutPgs] = useState([]);

  useEffect(() => {
      const now = new Date();
      const seconds = now.getSeconds().toString().padStart(2, '0');
      const fetcAboutData = async () => {
          try {
              const response = await fetch(`https://pgsexim.com/backend/wp-json/custom/v1/home-about-metadata?${seconds}`);
              if (!response.ok) {
                  throw new Error('Network response was not ok');
              }
              const jsonData = await response.json();
              sethomeAbout(jsonData);
          } catch (error) {
              console.error('Error fetching data:', error);
          }
      };
      fetcAboutData();

      const fetchAboutPgs = async () =>{
        try {
          const response = await fetch(`https://pgsexim.com/backend/wp-json/custom/v1/home-pgs-metadata?${seconds}`);
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          const jsonData = await response.json();
          setaboutPgs(jsonData);
      } catch (error) {
          console.error('Error fetching data:', error);
      }
      };
      fetchAboutPgs();
  
  }, []); 
  
  return (
    <>
      <main>
        <section className="commonSec aboutSec" id="sec1" key ="first">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="aboutImg">
                  <figure>
                    <img src={homeAbout.left_large_img} alt="" />
                  </figure>
                  <div className="aboutLogo">
                    <img src={homeAbout.left_small_img} alt="" />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="aboutTxt">
                  <h3>
                    <span className="subTxt">{homeAbout.small_title}</span>
                  </h3>
                  <h2 className="secH">{homeAbout.about_heading}</h2>
                  <div className="txtWrap">
                    <p dangerouslySetInnerHTML={{ __html: homeAbout.about_content }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="commonSec aboutSec2" key="second">
          <div className="gradient"></div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="aboutTxt">
                  <h3>
                    <span className="subTxt">{aboutPgs.pgs_small_title}</span>
                  </h3>
                  <h2 className="secH">{aboutPgs.pgs_about_heading}</h2>
                  <div className="txtWrap">
                    <p dangerouslySetInnerHTML={{ __html: aboutPgs.pgs_about_content }} />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="aboutImg">
                  <figure>
                    <img src={aboutPgs.pgs_left_large_img} alt="" />
                  </figure>
                  <div className="aboutLogo">
                    <img src={aboutPgs.pgs_left_small_img} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </main>
    </>
  );
};

export default Home;

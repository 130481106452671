import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "../css/homebanner.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { RiLinkedinFill } from "react-icons/ri";



const HomeBanner = () => {
    const [bannerSliderData, setBannerSliderData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
              const now = new Date();
              const seconds = now.getSeconds().toString().padStart(2, '0');
                const response = await fetch(`https://pgsexim.com/backend/wp-json/custom/v1/homepage-banner-metadata?${seconds}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const jsonData = await response.json();
                setBannerSliderData(jsonData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []); 


// const HomeBanner = () => {
  const homebanner = {
    dots: true,
    Array: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
//   console.log("Banner slider data:", bannerSliderData);
  return (
    <>
    <section className="bannerWrap"  style={{backgroundImage: `url(${bannerSliderData.banner_background_img})`}}>
        <div className="bannerSlider ">
        {bannerSliderData.length === 0 ? (
                    <p></p> // Or any other loading indicator
                ) : (
            <Slider {...homebanner}>
            
            {bannerSliderData.banner_slider.map((banner, index) => (
                
                <div className=""  key={banner.id}>
                
                    <div className="bannerItem">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-12">
                            <div className="bannerInner">
                            <div className="bannerContent">
                                <h1>{banner.banner_title}</h1>
                                <p>
                                {banner.banner_content}
                                </p>
                                <div className="bannerBtn">
                                <a href="{banner.explore_now_button.url}" className="btnH" key={banner.explore_now_button.id}>
                                    {banner.explore_now_button.title}
                                </a>
                                </div>
                            </div>
                            <div className="bannerPImg">
                                <img src={banner.banner_img} alt="{banner.banner_title}" />
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            ))}

            </Slider>
        )}

      <div className="swiper-pagination"></div>
    </div>
    <div className="bannerBottom">
      <svg
        className="waves"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28"
        preserveAspectRatio="none"
        shape-rendering="auto"
      >
        <defs>
          <path
            id="gentle-wave"
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
          />
        </defs>
        <g className="parallax">
          <use
            xlinkHref="#gentle-wave"
            x="48"
            y="0"
            fill="rgba(255,255,255,0.7"
          />
          <use
            xlinkHref="#gentle-wave"
            x="48"
            y="3"
            fill="rgba(255,255,255,0.5)"
          />
          <use
            xlinkHref="#gentle-wave"
            x="48"
            y="5"
            fill="rgba(255,255,255,0.3)"
          />
          <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
        </g>
      </svg>
    </div>
    <div className="social">

    {/* {bannerSliderData.banner_facebook_link && (
    <a href={bannerSliderData.banner_facebook_link}><FaFacebookF /></a>
    )}

    {bannerSliderData.banner_instagram_link && (
      <a href={bannerSliderData.banner_instagram_link}><FaInstagram /></a>
    )}

    {bannerSliderData.banner_linkedin_link && (
      <a href={bannerSliderData.banner_linkedin_link}><RiLinkedinFill /></a>
    )} */}

    </div>
    </section>
    </>
  );
};

export default HomeBanner;

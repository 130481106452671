import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import Tilt from "react-parallax-tilt";

const ServicesMobile = () => {
  const [serviceData, setserviceData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const now = new Date();
        const seconds = now.getSeconds().toString().padStart(2, "0");
        const response = await fetch(
          `https://pgsexim.com/backend/wp-json/custom/v1/home-service-metadata?${seconds}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        setserviceData(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Slick slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,  
  };

  return (
    <>
      <div className="commonSec serviceSec serviceMobi" id="sec3">
        <div className="gradient"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="text-center">
                <span className="subTxt">
                  {serviceData.service_small_title}
                </span>
              </h3>
              <h2 className="secH text-center">
                {serviceData.service_heading}
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <Slider {...settings}>
                {serviceData.length === 0
                  ? null
                  : serviceData.all_service.map((service, index) => (
                      <div className="serviceItem" key={index}>
                        <Tilt
                          glareEnable={true}
                          tiltMaxAngleX={10}
                          tiltMaxAngleY={10}
                        >
                          <div className="tiltComponent">
                            <div className="ItemBox">
                              <div className="serviceIcon">
                                <div className="iconBox">
                                  <img src={service.service_icon} alt="" />
                                </div>
                              </div>
                              <div className="serviceContent">
                                <h4>{service.service_heading}</h4>
                                <div className="serviceTxt">
                                  <p>{service.service_content}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tilt>
                      </div>
                    ))}
              </Slider>
            </div>
          </div>
        </div>
        <div className="sBottomLayer">
          <img src="assets/img/home/service-bg.png" alt="" />
        </div>
      </div>
    </>
  );
};

export default ServicesMobile;

import React, { useState, useEffect } from "react";
import img2 from "../image/home/application2.webp";

const ApplicationMobile = () => {
  const [activeAccordionIndex, setActiveAccordionIndex] = useState(0);
  const [backgroundImage, setBackgroundImage] = useState(img2);
  const [applicationData, setApplicationData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const now = new Date();
        const seconds = now.getSeconds().toString().padStart(2, "0");
        const response = await fetch(
          `https://pgsexim.com/backend/wp-json/custom/v1/home-application-metadata?${seconds}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        setApplicationData(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const toggleAccordion = (index) => {
    setActiveAccordionIndex(index === activeAccordionIndex ? null : index);
    // Change background image when accordion is clicked
    setBackgroundImage(
      applicationData.all_application_details[index].background_image
    );
  };

  const getActiveClass = (index) =>
    activeAccordionIndex === index ? "active" : "";

  return (
    <>
      <section id="applicationSec"
        className="applicationSec applicationMobile"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="container">
          <div className="row">
            <div class="col-lg-12">
              <h2 class="secH text-center">
                {applicationData.application_heading}
              </h2>
            </div>
          </div>
        </div>
        <div className="applicationTabMain">
          {applicationData.length === 0 ? (
            <p>Loading..</p>
          ) : (
            <>
              <div className="accordion">
                {applicationData.all_application_details.map(
                  (application, index) => (
                    <AccordionItem
                      key={index}
                      application={application}
                      index={index}
                      isOpen={index === activeAccordionIndex}
                      toggleAccordion={() => toggleAccordion(index)}
                      activeClass={getActiveClass(index)}
                    />
                  )
                )}
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

const AccordionItem = ({
  application,
  index,
  isOpen,
  toggleAccordion,
  activeClass,
}) => {
  return (
    <div className={`accordion-item ${activeClass}`}>
      <button className="accordion-button" onClick={toggleAccordion}>
        {application.tab_name}
      </button>
      <div className={`accordion-content ${isOpen ? "open" : ""}`}>
        <div className="applicationCon">
          <h2 className="secH2">
            <span className="secH3 ">{application.application_title}</span>
          </h2>
          <p>{application.application_description}</p>
          <div className="applicationImg">
            <img
              className="applicationImg1"
              src={application.application_image}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationMobile;

import React, { useEffect, useRef, useState } from "react";
import "../css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import "../css/responsive.css"

const Header = () => {
  const [headerNav, setheaderNav] = useState([]);
  const [scrolltopdata, setscrolltopdata] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const now = new Date();
        const seconds = now.getSeconds().toString().padStart(2, "0");
        const response = await fetch(
          `https://pgsexim.com/backend/wp-json/custom/v1/homepage-nav-metadata?${seconds}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        setheaderNav(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    window.addEventListener("scroll", () => {
      if (window.scrollY < 15) {
        setscrolltopdata("");
      } else {
        setscrolltopdata("is-sticky");
      }
    });
  }, []);

  useEffect(() => {}, []);
  const [showNavbar, setShowNavbar] = React.useState(false);
  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
    const navbar = document.getElementById("hamMenu");
    navbar.classList.toggle("active");
  };
  const boxOutsideClick = () => {
    const navbar = document.querySelector(".hamMenu");
    navbar.classList.remove("active");
    const menubar = document.querySelectorAll(".menuWrapMobi");
    menubar.forEach((element) => {
      element.classList.remove("active");
      element.classList.add("false");
    });
    if (showNavbar == true) {
      setShowNavbar(!showNavbar);
    }
  };

  document.querySelector("body").addEventListener("click", (event) => {
    const clickedElement = event.target;
    const clickedElementId = clickedElement.id;
    const excludedElementId = "hamMenu";
    if (clickedElementId !== excludedElementId) {
      boxOutsideClick();
    }
  });

  return (
    <>
      {" "}
      <div className="headerMain">
        <header className={`headerWrap headerWrapMobi ${scrolltopdata}`}>
          <div className="container ">
            <div className="row">
              <div className="col-lg-12">
                <div>
                  <div className="headerInner">
                    <div className="leftLogo">
                      <a href="https://pgsexim.com/">
                        <img src={headerNav.nav_left_logo} alt="" />
                      </a>
                    </div>
                    <div className="rightlogo">
                      <a href="#">
                        <img src={headerNav.nav_right_logo} alt="" />
                      </a>
                    </div>
                  </div>
                  <div
                    className="hamMenu"
                    id="hamMenu"
                    onClick={handleShowNavbar}
                  >
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div
          className={`menuWrap  menuWrapMobi ${
            showNavbar == true ? "active" : "false"
          }`}
        >
          {headerNav.length === 0 ? (
            <>
              {/* <h2 class="secH text-center">Loading...</h2> */}
              <h2 class="secH text-center"></h2>
            </>
          ) : (
            <ul>
              {headerNav.nav_menu.map((nav, index) => (
                <>
                  <li key={index}>
                    <a
                      onClick={boxOutsideClick}
                      href={nav.menu_url_}
                      data-rel="body_nav2"
                    >
                      {nav.menu_name}
                    </a>
                  </li>
                </>
              ))}
            </ul>
          )}
        </div>
      </div>
      <header className={`headerWrap headerWrapDest ${scrolltopdata}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="headerInner">
                <div className="leftLogo">
                  <a href="{headerNav.home_url}">
                    <img src={headerNav.nav_left_logo} alt="" />
                  </a>
                </div>
                <div className="menuWrap">
                  {headerNav.length === 0 ? (
                    <>
                      {/* <h2 class="secH text-center">Loading...</h2> */}
                      <h2 class="secH text-center"></h2>
                    </>
                  ) : (
                    <ul>
                      {headerNav.nav_menu.map((nav, index) => (
                        <>
                          <li>
                            <a href={nav.menu_url_} data-rel="body_nav2">
                              {nav.menu_name}
                            </a>
                          </li>
                        </>
                      ))}
                    </ul>
                  )}
                </div>
                <div className="rightlogo">
                  <a href="#">
                    <img src={headerNav.nav_right_logo} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
